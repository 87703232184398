import { ApplicationEnvironment } from "./application-environment";

export const environment: ApplicationEnvironment = {
    key: 'Dev',
    buildVersion: '1.8.1-alpha.7',
    apiBaseUrl: '/api/internal/v1',
    userPhotoUrl: 'https://intranet.swisscom.com/Lists/CustomPortraits',
    // AgileOrg 1.0
    agileOrgFrtoned: 'https://agileorgdev.scapp-corp.swisscom.com',
    agileOrgAPI: 'https://agileorgapidev.scapp-corp.swisscom.com',
    proxyAPIKey: 'dqeIKJCnWYbEjyCZK4JjEl9KIkQiJGuputsHalS5kA8KfkHBPOkkjFjl87fIXq06',
    supportUrl: 'https://jira.swisscom.com/servicedesk/customer/portal/10',
    communityUrl: 'https://teams.microsoft.com/l/channel/19%3a6abfea2b972648f4807bddbbb9b2d13d%40thread.skype/AgileOrg?groupId=4e78ab9d-6a99-4aad-94c7-d04e17ed1586&tenantId=364e5b87-c1c7-420d-9bee-c35d19b557a1',
    documentationUrl: 'https://wiki.swisscom.com/x/g1M0Hw',
    releaseNoteUrl: 'https://wiki.swisscom.com/x/aLf9Ow',
    firebaseConfig: {
        apiKey: "AIzaSyAAtNMOo6kYEpOwt9szIds1mbIEUUhdSfs",
        authDomain: "anyorg-dev-env.firebaseapp.com",
        projectId: "anyorg-dev-env",
        storageBucket: "anyorg-dev-env.appspot.com",
        messagingSenderId: "406597668377",
        appId: "1:406597668377:web:e63adb49341c0caec72488",
        measurementId: "G-SMXJR06H3C",
      },
};
